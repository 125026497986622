import React from "react";

const Footer = () => {
  return (
    <>
      <footer class="bg-dark text-center text-lg-start">
        <div
          class="textC"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            height: "6rem",
            textAlign: "center",
          }}
        >
          <h6 style={{ color: "white", paddingTop: "2.5rem" }}>
            © 2023 Copyright All Rights Reserved.
          </h6>
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=918208435821&text=Hello"
              class="btn-whatsapp-pulse"
              target="_blank"
            >
              <i class="fab fa-whatsapp "></i>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
